<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                v-if="confirmed"
                class="text-center"
            >
                <v-icon
                    size="100"
                >
                    mdi-email-check
                </v-icon>
                <div class="text-center">
                    <h1>{{ $t('emailConfirmed') }}</h1>
                    <span>{{ $t('customerportal-addCreditCard-thanks') }}!</span>
                </div>
                <v-btn
                    class="my-2"
                    :to="{ name: 'Login' }"
                >
                    {{ $t('customerportal-goToLogin') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    data() {
        return {
            email: null,
            token: null,

            confirmed: false
        }
    },
    computed: {
    },
    created() {
        this.email = this.$route.query.email   
        this.token = this.$route.query.token   

        this.ConfirmEmail({
            email: this.email,
            token: this.token
        })
            .then(data => {
                if(data.success){
                    this.confirmed = true
                }   
            })
    },    
    methods: {
        ...mapActions({
            ConfirmEmail: 'ConfirmEmail'
        })
    }
}
</script>
